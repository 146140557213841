/**
 * @file   展览详情页
 * @create macaiqi 2022/09/19
 * @update
 */
import React from 'react';
import { useQuery } from '@portal-site/core';
import { Skeleton, Picture } from '@portal-site/components';
import classnames from 'classnames';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
function BaseExhDetail(props) {
  const { id } = props.match.params;
  const { response = { records: {} }, loading } = useQuery({
    api: 'queryExhibitionBaseById',
    options: id
  });
  const { records } = response;
  return (
    <Skeleton loading={loading} render={() => <Skeleton.Paragraph rows={10} />}>
      <Detail data={records} />
    </Skeleton>
  );
}
// eslint-disable-next-line complexity
const Detail = ({ data }) => {
  if (Object.keys(data).length === 0) return null;
  const { title, content, place, imgSrc, startTime, exhDate, endTime } = data;
  dayjs.extend(isBetween);

  return (
    <div className="activity-detail">
      <div className="allbox">
        <div className="title">{title}</div>

        <div className="cont-box">
          <div className="left">
            <Picture source={imgSrc} />
          </div>
          <div className="tips">
            <div className="top">
              <div className="p">
                <span className="t1">展览时间</span>
                <span className="t2">{!startTime && !endTime ? '常年陈列' : exhDate}</span>
              </div>
              {place && (
                <div className="p">
                  <span className="t1">展览地点</span>
                  <span className="t2">{place}</span>
                </div>
              )}
            </div>
            <div className="btnbox">
              <div className={classnames('btn end')}>展览进行中</div>
            </div>
          </div>
        </div>

        {content && (
          <>
            <h3 className="sec-title">展览详情</h3>
            <div className="cont" dangerouslySetInnerHTML={{ __html: content }} />
          </>
        )}
      </div>
    </div>
  );
};

export default BaseExhDetail;
