/**
 * @file   馆藏珍品
 * @create lixiang 2023/04/23
 * @update
 */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useQuery } from '@portal-site/core';
import { usePage } from '../../../../components/Hooks';
import { Pager } from '@portal-site/pager';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Picture } from '@portal-site/components';
import classNames from 'classnames';
import { changePictureSize } from '@/utils';
import Loading from '@/app/components/Loading';
import '../index.scss';

const ColType = ({ setType, type, setPage }) => {
  const assetsPrefix = window.ENV_IMG_PREURL;
  const options = useMemo(() => {
    return {
      pagination: {
        size: 10,
        page: 1
      }
    };
  }, []);
  const { response = { records: [] } } = useQuery({
    api: 'queryExhibitTypeList',
    options: options
  });
  const { records } = response;
  const { search, pathname } = useLocation();

  const history = useHistory();
  const handSearch = useCallback(
    (val) => {
      setPage(1);
      const url = new URLSearchParams(search);
      url.set('type', val);
      setType(val);
      history.push({
        pathname: pathname,
        search: `?${url.toString()}`
      });
    },
    [history, pathname, setType, search, setPage]
  );
  return (
    <ul className="coltypelist">
      {records
        .filter((i) => i.type !== 'coldata')
        .map((item) => {
          return (
            <li
              key={item.id}
              className={classNames({ active: item.type === type })}
              onClick={() => {
                handSearch(item.type);
              }}
            >
              <img src={assetsPrefix + item.imgSrc} alt="" />
              <span className="name">{item.name}</span>
            </li>
          );
        })}
    </ul>
  );
};

const Age = function ({ a, setA }) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('年代');
  const handleClear = () => {
    setName('年代');
    setA('');
    setOpen(false);
  };
  useEffect(() => {
    if (a === '') {
      setName('年代');
    }
  }, [a])
  const option = useMemo(() => {
    return {
      api: 'queryDictionary',
      options: {
        namespace: 'CMS_EXHIBIT_AGE_OPTION',
        systemCode: 'BWG_YB',
        prefix: 'CMS03'
      }
    };
  }, []);
  const { response = { records: [] } } = useQuery(option);
  const { records } = response;
  return (
    <div className={open ? 'typeage active' : 'typeage'} onClick={(e) => setOpen(!open)}>
      <p className="box">
        <span>{name}</span>
        {open ? (
          <i className="iconfont iconclear" onClick={handleClear} />
        ) : (
          <i className="iconfont iconxiangxia" />
        )}
      </p>
      <div className={classNames('mask', { open })} />
      <ul className="list">
        {records.map((item) => {
          return (
            <li
              // className="list-i"
              className={classNames('list-i', { active: item.type === a })}
              key={item.id}
              onClick={() => {
                setName(item.name);
                setA(item.type);
              }}
            >
              {item.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Type = function ({ type, setType, setKey, setPage, setAge, channelNo }) {
  const [k, setK] = useState('');
  const [a, setA] = useState('');

  // 设置search
  const { search, pathname } = useLocation();

  const history = useHistory();

  const handSearch = useCallback(() => {
    const url = new URLSearchParams(search);
    setPage(1);
    setKey(k);
    setAge(a);
    url.set('keywords', k);
    history.push({
      pathname: pathname,
      search: `?${url.toString()}`
    });
  }, [setPage, setKey, k, setAge, a, history, pathname, search]);
  useEffect(() => {
    console.log(pathname)
    setPage(1);
    setKey('');
    setAge('');
    setA('')
    setK('')
  }, [pathname, setAge, setKey, setPage])
  return (
    <div className="collection-head">
      <div className="search-box">
        <Age a={a} setA={setA} />
        <input
          className="text"
          type="text"
          placeholder="文物名称"
          value={k}
          onChange={(e) => {
            setK(e.target.value);
          }}
        />

        <button
          className="btn"
          onClick={() => {
            handSearch();
          }}
        >
          搜索
        </button>
      </div>
      {channelNo === 'Treasure' && <ColType setType={setType} type={type} setPage={setPage} />}
    </div>
  );
};
const List = function ({ type, keywords, initPage, age, setPage, channelNo }) {
  let t = '';
  if (!type && channelNo === 'Treasure') {
    t = 'taociqi,zhumuqi,qingtongqi,baoyushi,shizhiwenwu,shuhuatapian,qita';
  } else if (type && channelNo === 'Treasure') {
    t = type;
  } else if (channelNo === 'ColDatabase') {
    t = 'coldata';
  }
  const option = useMemo(
    () => ({
      api: 'queryExhibitList',
      options: {
        params: {
          type: t,
          name: keywords,
          // isOwn: true,
          age: age
        },
        pagination: {
          page: initPage,
          size: 6
        }
      }
    }),
    [t, keywords, age, initPage]
  );
  const { response = { records: [], pages: 1, page: 1 }, loading } = useQuery(option);
  const { records, pages, page } = response;
  return (
    <div className="allcenter">
      {loading ? (
        <Loading />
      ) : (
        <>
          <ul className="col-list">
            {records.map((item) => {
              return (
                <li key={item.id} className="col-list-i">
                  <Link to={`/Collection/${channelNo}/${item.id}/${item.groupId}`} target="_blank">
                    <Picture source={changePictureSize(item.imgSrc, 600)} />
                    <h3 className="title ellipsis">{item.title}</h3>
                  </Link>
                  {item.modelUrl && (
                    <a href={item.modelUrl} target="_blank" className="threed" rel="noreferrer">
                      <i className="iconfont icon-threed" />
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
          <Pager
            containerClassName="mypager"
            forcePage={page}
            pageCount={pages}
            onPageChange={(page) => setPage(page)}
          />
        </>
      )}
    </div>
  );
};
function Treature() {
  const { search, pathname } = useLocation();
  const url = new URLSearchParams(search);

  const channelNo = pathname.split('/')[pathname.split('/').length - 1];
  const [initPage, setPage] = usePage();
  const [age, setAge] = useState('');
  const [type, setType] = useState(url.get('type'));
  const [keywords, setKey] = useState(url.get('keywords'));

  useEffect(() => {
    const url = new URLSearchParams(search);
    // 在 search 参数变化时触发的操作
    setAge('');
    setType(url.get('type'));
    setKey(url.get('keywords'));
  }, [search]);

  return (
    <>
      <Type
        type={type}
        setType={setType}
        setKey={setKey}
        setPage={setPage}
        setAge={setAge}
        channelNo={channelNo}
      />
      <List
        type={type}
        keywords={keywords}
        initPage={initPage}
        setPage={setPage}
        age={age}
        channelNo={channelNo}
      />
    </>
  );
}

export default Treature;
