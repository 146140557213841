import React, { Component } from 'react';
import Layer from './Layer';
import './index.scss';

export default class Info extends Component {
  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { children, ...rest } = this.props;
    return (
      <Layer
        containerStyle={{
          top: 200
        }}
        {...rest}
      >
        <div className="mp-layer-title">提示</div>
        <div className="mp-layer-info">{children}</div>
        <div className="mp-layer-infobtn" onClick={this.onClose}>
          <span>确认</span>
        </div>
      </Layer>
    );
  }
}
