/**
 * @file   展览详情页
 * @create macaiqi 2022/09/19
 * @update
 */
import React from 'react';
import { useQuery } from '@portal-site/core';
import { Skeleton, Picture } from '@portal-site/components';
import { showDateTime, changePictureSize } from '@/utils';
import classnames from 'classnames';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
function TempExhDetail(props) {
  const { id } = props.match.params;
  const { response = { records: {} }, loading } = useQuery({
    api: 'queryExhibitionTempById',
    options: id
  });
  const { records } = response;
  return (
    <Skeleton loading={loading} render={() => <Skeleton.Paragraph rows={10} />}>
      <Detail data={records} />
    </Skeleton>
  );
}
// eslint-disable-next-line complexity
const Detail = ({ data }) => {
  const isEn = window.isEn;
  if (Object.keys(data).length === 0) return null;
  const {
    title,
    content,
    // publishDate,
    // appimgSrc,
    // price,
    place,
    imgSrc,
    startTime,
    endTime
  } = data;
  dayjs.extend(isBetween);

  let end = dayjs().isAfter(dayjs(endTime));
  let unstart = dayjs(startTime).isAfter(dayjs());
  let doing = dayjs().isBetween(dayjs(startTime), dayjs(endTime));
  return (
    <div className="activity-detail">
      <div className="allbox">
        <div className="title">{title}</div>

        <div className="cont-box">
          <div className="left">
            <Picture source={changePictureSize(imgSrc, 600)} />
          </div>
          <div className="tips">
            <div className="top">
              <div className="p">
                <span className="t1">{!isEn ? `展览时间` : 'Date'}</span>
                <span className="t2">
                  {!isEn
                    ? showDateTime(
                        dayjs(startTime).format('YYYY-MM-DD'),
                        dayjs(endTime).format('YYYY-MM-DD'),
                        true
                      )
                    : `${startTime && dayjs(startTime).format('YYYY/MM/DD')} - ${endTime &&
                        dayjs(endTime).format('YYYY/MM/DD')}`}
                </span>
              </div>
              {place && (
                <div className="p">
                  <span className="t1">{isEn ? `Location ` : `展览地点`}</span>
                  <span className="t2">{place}</span>
                </div>
              )}
            </div>
            <div className="btnbox">
              <div className={classnames('btn', { end })}>
                {!isEn
                  ? end
                    ? `展览已结束`
                    : unstart
                    ? `展览未开始`
                    : doing
                    ? `展览进行中`
                    : ''
                  : ''}
                {isEn ? (end ? `ended` : unstart ? `not started` : doing ? `doing` : '') : ''}
              </div>
            </div>
          </div>
        </div>

        {content && (
          <>
            <h3 className="sec-title">{!isEn ? `展览详情` : 'Details'}</h3>
            <div className="cont" dangerouslySetInnerHTML={{ __html: content }} />
          </>
        )}
      </div>
    </div>
  );
};

export default TempExhDetail;
