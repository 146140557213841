/**
 * @file   数据读取
 * @create zhangchen 2020/11/06
 */
import service from './index';
import { mapDataToRoute, cmsDataProvider, RootRouteMap } from '@portal-site/data';
import { CMSGATEWAY } from './http';

export const dataProvider = (http) => ({
  ...cmsDataProvider(http, ''),
  ...service(http),
  ...{
    queryRoutes: function() {
      const endPoint = `/${CMSGATEWAY}/api/queryAllChannel/CN`;
      return new Promise((resolve, reject) => {
        http
          .get(endPoint, {
            channelNo: 'CN'
          })
          .then((res) => {
            const root = new RootRouteMap();
            const routeMaps = res.data.data.records.map((r) => {
              return mapDataToRoute(r, root);
            });
            root.children = routeMaps;
            resolve(root);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    }
  }
});
