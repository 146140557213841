/**
 * @file   常展列表
 * @create
 * @update zhangchen 2020/12/07 折叠超出的简介
 */
import React, { useMemo } from 'react';
import { useQuery } from '@portal-site/core';
import { Pager } from '@portal-site/pager';
import { usePage } from '../../../../components/Hooks';
import Baselist from '../../../common/List/Baselist';
import '../style.scss';

function BaseExhibition() {
  const [initPage, setPage] = usePage(1);
  const tempOptions = useMemo(() => {
    return {
      pagination: {
        size: 6,
        page: initPage
      }
    };
  }, [initPage]);
  const { response = { records: [], pages: 1, page: 1 } } = useQuery({
    api: 'queryExhibitionBaseList',
    options: tempOptions
  });
  const { records, pages, page } = response;

  return (
    <div className="baseexh">
      <Baselist data={records} prePath="/Exhibition/BaseExhibition" isExh />
      <Pager
        containerClassName="mypager"
        hrefCreator={(page) => `?page=${page}`}
        forcePage={page}
        pageCount={pages}
        onPageChange={(page) => setPage(page)}
      />
    </div>
  );
}

export default BaseExhibition;
