/**
 * @file 单选组件容器组件
 * @create wangdekui 2020/10/12
 */
import { css } from 'emotion';
import React from 'react';

/**
 * 单选组件容器组件
 */
export const RadioGroup = ({ name, children }) => {
  const radios = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      name
    });
  });
  return (
    <div
      className={css`
        display: flex;
        flex-wrap: wrap;
        label {
          margin-right:1rem;
        }
      `}
    >
      {radios}
    </div>
  );
};
