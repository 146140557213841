import { useState, useEffect } from 'react';
import { getInitPage, parseSearch } from '../../../utils/platform';
import { useLocation, useHistory } from 'react-router-dom';
const usePage = function () {
  const { pathname, search } = useLocation();

  // initPage
  const [initPage, setInitPage] = useState(getInitPage(parseSearch(search)));
  const [nextPage, setPage] = useState(initPage);

  // history push
  const history = useHistory();
  useEffect(() => {
    let isUnmount = false;
    const url = new URLSearchParams(search);
    // 这个page可能为0 要判断
    const page = Number(url.get('page'));
    const currentPage = page || 1;
    if (currentPage === nextPage) return;
    // goback
    if (currentPage !== nextPage && initPage === nextPage  && !isUnmount) {
      setPage(currentPage);
      setInitPage(currentPage);
      return () => {isUnmount = true};
    }

    // onPageChange
    if (currentPage !== nextPage) {
      url.set('page', nextPage);
      setInitPage(nextPage);
      history.push({
        pathname: pathname,
        search: `?${url.toString()}`
      });
    }
  }, [initPage, nextPage, search, pathname, history]);
  return [initPage, setPage];
};

export default usePage;
