import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

const MyLink = function (props) {
  const { linkUrl, handleClick, children, className = '', ...rest } = props;
  const clickHandle = useCallback(
    (e) => {
      e.preventDefault();
      if (typeof handleClick === 'function') {
        handleClick();
      }
    },
    [handleClick]
  );
  return handleClick ? (
    <a href="true" onClick={clickHandle} className={className}>
      {children}
    </a>
  ) : Boolean(linkUrl) === false ? (
    <Link {...rest} className={className}>
      {children}
    </Link>
  ) : (
    <a href={linkUrl} {...rest} target="_blank" rel="noopener noreferrer" className={className}>
      {children}
    </a>
  );
};

export default MyLink;
