/**
 * @file   总页面
 * @create zhangchen 2020/11/06
 * @update zhangchen 2020/11/06 去掉多余内容
 */

import 'regenerator-runtime/runtime.js';
import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { PortalSite } from '@portal-site/core';
import { routesMap } from '@portal-site/data';
import { createBrowserHistory } from 'history';
import { PortalUIProvider } from '@portal-site/components';
import Header from './common/Header';
import Footer from './common/Footer';
import { pcRoutes } from './routes';
import 'normalize.css';
import ErrorCatch from '../components/ErrorCatch';
import ClsNameFix from '../components/ClsNameFix';
// import SearchPage from './common/Search';
import { http } from './service/http';
import 'viewerjs/dist/viewer.min.css';
import NoMatch from '../components/NoMatch';
import { dataProvider } from './service/dataProvider';
import { PagerConfig } from '@portal-site/pager';
const ENV_IMG_PREURL = window.ENV_IMG_PREURL;

const App = ({ children }) => {
  return (
    <PortalSite
      models={[routesMap]}
      dataProvider={dataProvider(http)}
      history={createBrowserHistory()}
      root="cn"
    >
      <PortalUIProvider
        value={{
          assetsPrefix: ENV_IMG_PREURL
        }}
      >
        <ClsNameFix>
          <ErrorCatch>
            <PagerConfig.Provider
              value={{
                showJump: true,
                showTotal: true,
                containerClassName: 'mypager'
              }}
            >
              <Page />
            </PagerConfig.Provider>
          </ErrorCatch>
        </ClsNameFix>
      </PortalUIProvider>
    </PortalSite>
  );
};
const Page = () => {
  const { pathname } = useLocation();
  const isIndex = pathname === '/' || pathname === '/Home';
  // const isSearch = location.pathname === '/Search';

  // 不需要allcenter的栏目
  const noAllCenter = pathname === '/Survey/Introduction';
  const channelNo = pathname.split('/')[pathname.split('/').length - 1];
  return (
    <>
      <Header isIndex={isIndex} />
      {isIndex ? (
        <div className="index-cont">
          <Switch>
            {pcRoutes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              );
            })}
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </div>
      ) : (
        <div className={`inner-cont ${channelNo.toLowerCase()}-body`}>
          <div className={noAllCenter ? '' : 'allcenter'}>
            <Switch>
              {pcRoutes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                );
              })}
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};
export default App;
