/**
 * @file   留言列表
 * @create lixiang 2020/10/29
 * @update
 */

import React, { useRef, useCallback, useMemo, useState } from 'react';
import { usePage } from '../../../components/Hooks';
import { Pager } from '@portal-site/pager';
import { useQuery } from '@portal-site/core';
import './style.scss';
import LeavewordForm from './Form';

// 留言列表
const List = function ({ setShowForm }) {
  const input = useRef();
  const [initPage, setPage] = usePage();
  const [key, setKey] = useState('');
  // enter键搜索
  const handleKeyDown = useCallback((e) => {
    if (e.charCode === 0 && e.keyCode === 13) {
      e.preventDefault();
      setKey(input.current.value);
    }
  }, []);
  const handleClick = (e) => {
    // setKey(e.target.value);
    setKey(input.current.value);
  };
  const options = useMemo(() => {
    return {
      api: 'queryMessageList',
      options: {
        params: {
          // keywords: key
          content: key
        },
        pagination: {
          page: initPage,
          size: 10
        }
      }
    };
  }, [key, initPage]);
  const {
    response = {
      records: [],
      pages: 1,
      page: 1
    }
  } = useQuery(options);

  const { records, pages, page } = response;

  return (
    <div className="message-box">
      <div className="leavewordsearch">
        <div className="searchbox">
          <input
            onKeyDown={handleKeyDown}
            placeholder="请输入关键词搜索留言内容……"
            ref={input}
            className="input"
          />
          <div onClick={handleClick} className="btn">
            搜&nbsp;索
          </div>
        </div>

        <div className="btn b1" onClick={() => setShowForm(true)}>
          我要留言
        </div>
      </div>
      <div className="leavewordtopcont">
        <i>!</i>
        <p>留言须知：</p>
        <p>1.请自觉遵守中华人民共和国的各项有关法律法规。</p>
        <p>2.不得发布任何色情、非法、以及危害国家安全的言论。</p>
        <p>3.严禁链接有关政治, 色情, 宗教, 迷信等违法信息。</p>
        <p>4.承担一切因您的行为而直接或间接导致的民事或刑事法律责任。</p>
        <p>5.管理员有权保留或删除留言板中的任意内容。</p>
      </div>
      <ul className="leavewordlist">
        {records.map((item) => {
          const time = item.createTime.split(' ')[0];
          return (
            <li key={item.id}>
              <div className="question">
                <div className="icon">问</div>
                <div className="content">
                  <h3 className="title">{item.content}</h3>
                  <p className="cont">
                    (姓名：{item.name}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;发布时间：
                    {time})
                  </p>
                </div>
              </div>
              {item.reply && <div className="answer">
                <div className="icon">答</div>
                <div className="content">{item.reply}</div>
              </div>}
            </li>
          );
        })}
      </ul>
      <Pager
        containerClassName="mypager"
        forcePage={page}
        pageCount={pages}
        onPageChange={(page) => setPage(page)}
      />
    </div>
  );
};

// 留言列表
function LeavewordList() {

  const [showForm, setShowForm] = useState(false);
  return (
    <div className="innerbox">
      {showForm ? <LeavewordForm setShowForm={setShowForm} /> : <List setShowForm={setShowForm} />}
    </div>
  );
}

export default LeavewordList;
