/**
 * @file   临展列表
 * @create zhangchen 2022/09/07
 */
import React, { useMemo } from 'react';
import { Picture } from '@portal-site/components';
import { useQuery } from '@portal-site/core';
import { Pager } from '@portal-site/pager';
import { usePage } from '../../../../components/Hooks';
import { getNowFormatDate, changePictureSize } from '@/utils';
import { Link } from 'react-router-dom';
import cls from 'classnames';
import '../style.scss';
const RowTwoPicList = ({ list }) => {
  const timeNow = getNowFormatDate();
  let isNow = false;
  return (
    <ul className="row-two-pic-list-temp">
      {list.map((item, index) => {
        if (!item.endTime) {
          isNow = true;
        } else {
          isNow = item.endTime >= timeNow;
        }
        return (
          <li key={index}>
            <Link to={`/Exhibition/TempExhibition/${item.id}`}>
              <Picture source={changePictureSize(item.imgSrc, 600)} />
              <div className="bottom">
                <h2 className="ellipsis">{item.title}</h2>
                <Link className="com-btn-more" to={`/Exhibition/TempExhibition/${item.id}`}>
                  查看详情
                </Link>
              </div>
              <p className="time ellipsis">
                <i className="iconfont icon-shijian1" />
                <span>展览时间：</span>
                {item.exhDate}
                <span className={cls('statue', { now: isNow })}>
                  {isNow ? '【在展】' : '【结束】'}
                </span>
              </p>
              <p className="time ellipsis">
                <i className="iconfont icon-dingwei" />
                <span>展览地点：</span>
                {item.place}
              </p>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

function TempExhibition(props) {
  const [initPage, setPage] = usePage(1);
  const tempOptions = useMemo(() => {
    return {
      pagination: {
        size: 8,
        page: initPage
      },
      params: {
        // type: 'CMS0702'
      }
    };
  }, [initPage]);
  const { response = { records: [], pages: 1, page: 1 } } = useQuery({
    api: 'queryExhibitionTempList',
    options: tempOptions
  });
  const { records, pages, page } = response;
  return (
    <div className="tempexh" key={props.location.key}>
      <RowTwoPicList list={records} />
      <Pager
        containerClassName="mypager"
        hrefCreator={(page) => `?page=${page}`}
        forcePage={page}
        pageCount={pages}
        onPageChange={(page) => setPage(page)}
      />
    </div>
  );
}

export default TempExhibition;
