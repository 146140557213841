/**
 * @file 单选组件
 * @create wangdekui 2020/10/12
 */
import { css } from 'emotion';
import React from 'react';

/**
 * 单选组件
 */
export const Radio = ({ label, name, ...rest }) => {
  return (
    <label
      className={css`
        position: relative;
        display: flex !important;
        align-items: center;
        justify-content: flex-start;
        margin: 12px 0;
        flex-wrap: nowrap;
        cursor: pointer;
        input[type='radio'],
        input[type='checkbox'] {
          display: none;
        }
        input:checked + span::before {
          opacity: 1;
        }
      `}
    >
      <input name={name} type="radio" {...rest} />
      <span
        className={css`
          position: relative;
          margin-right: 8px;
          width: 16px;
          height: 16px;
          border: 1px solid #6f1b1b;
          border-radius: 4px;
          &::before {
            content: '✓';
            position: absolute;
            top: 4px;
            left: 3px;
            display: block;
            width: 8px;
            height: 8px;
            color: #6f1b1b;
            opacity: 0;
            transition: all 0.1s ease-in;
            line-height: 8px;
          }
        `}
      />
      <span>{label}</span>
    </label>
  );
};
