/**
 * @file 留言表单
 * @create wangdekui 2020/10/30
 */
import { Button } from '@portal-site/components';
import { css, cx } from 'emotion';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { validator } from '../../../../utils/platform';
// import { Radio } from '../../../components/Radio';
import { Radio } from '../../../components/Radio/Radio';
import { RadioGroup } from '../../../components/Radio/RadioGroup';
import { Input, labelStyle } from '../../common/Field/Input';

import { ErrorMessage } from '@hookform/error-message';
import { Textarea } from '../../common/Field/Textarea';
import { useDataProvider } from '@portal-site/core';
import Layer from '../../../components/Layer';

// import { useAuthBtn } from '../../../components/Hooks/useAuthBtn';
export const Form = ({ setShowForm }) => {
  const addMessage = useDataProvider('addMessage');
  const [open, setOpen] = useState(false); // 成功提示

  const { register, handleSubmit, formState, errors, setValue, reset } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phoneNumber: '',
      type: 0
    },
    shouldFocusError: false
  });
  register({ name: 'type', type: 'custom' });
  const { isSubmitting } = formState;
  const onSubmit = (values) => {
    return new Promise((resolve) => {
      const options = { isAnonymous: 1, source: 'PT', ...values };
      addMessage(options)
        .then((result) => {
          if (result.code === 200) {
            setOpen(true);
          } else {
            alert(result.msg);
          }
          reset();
        })
        .catch((err) => console.log(err))
        .finally(resolve);
    });
  };

  return (
    <div className="mainform leavewordform">
      <div className="back " onClick={() => setShowForm(false)} >
        <i className='iconfont icon-xiangzuo' />
        返回
      </div>
      <form className="">
        <ul>
          <li>
            <Input
              ref={register({
                required: {
                  value: true,
                  message: '请输入您的姓名'
                },
                pattern: {
                  value: validator().fullNameRegex,
                  message: '请输入正确的姓名'
                }
              })}
              label="您的姓名"
              required
              name="name"
            />
            <span className="tip error">
              <ErrorMessage name="name" errors={errors} />
            </span>
          </li>
          <li>
            <Input
              ref={register({
                // required: {
                //   value: true,
                //   message: '请输入您的邮箱'
                // },
                pattern: {
                  value: validator().emailRegex,
                  message: '请输入正确的邮箱'
                }
              })}
              label="电子邮箱"
              // required
              name="email"
            />
            <span className="tip error">
              <ErrorMessage name="email" errors={errors} />
            </span>
          </li>
          <li>
            <Input
              ref={register({
                required: {
                  value: true,
                  message: '请输入您的联系电话'
                },
                pattern: {
                  value: validator().phoneNumRegex,
                  message: '请输入正确的联系电话'
                }
              })}
              label="联系电话"
              required
              name="phoneNumber"
            />
            <span className="tip error">
              <ErrorMessage name="phoneNumber" errors={errors} />
            </span>
          </li>
          <li>
            <label className={cx(labelStyle, 'required')}>留言类型:</label>
            <div
              className={css`
                display: inline-block;
                vertical-align: sub;
              `}
            >
              <RadioGroup name="type">
                <Radio
                  onChange={() => setValue('type', 0)}
                  value={0}
                  defaultChecked={true}
                  label="咨询"
                />
                <Radio onChange={() => setValue('type', 1)} value={1} label="投诉" />
                <Radio onChange={() => setValue('type', 2)} value={2} label="建议" />
                <Radio onChange={() => setValue('type', 3)} value={3} label="举报" />
              </RadioGroup>
            </div>
          </li>
          <li>
            <Textarea
              ref={register({
                required: {
                  value: true,
                  message: '请填写您的留言'
                },
                max: {
                  value: 140,
                  message: '请少于140字'
                }
              })}
              placeholder="不超过140字"
              required
              rows={8}
              label="留言内容"
              name="content"
            />
            <span className="tip error">
              <ErrorMessage name="content" errors={errors} />
            </span>
          </li>
        </ul>
      </form>
      <div className="btn-box">
        <Button
          style={{
            backgroundColor: '#a2611b'
          }}
          loading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          size="md"
          type="primary"
        >
          提交
        </Button>
        <Button onClick={() => setShowForm(false)} size="md">
          返回
        </Button>
      </div>
      <Layer
        containerStyle={{
          width: 260,
          height: 154
        }}
        width={500}
        visible={open}
        onClose={() => {
          setOpen(false);
          setShowForm(false)
        }}
      >
        <p>提交成功，请等待审核！</p>
      </Layer>
    </div>
  );
};

export default Form;
