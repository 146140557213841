/**
 * @file   http
 * @create wangdekui 2020/09/27
 * @update
 */

import { createHttpClient } from '@portal-site/data';

const ENV_API = window.ENV_API;
export const CMSGATEWAY = window.ENV_CMS;
export const FILEGATEWAY = 'sw-file';
export const SEARCHGATEWAY = 'sw-es';
export const appId = '29c9e2e2b8e64b2785f418da5b8007b7';
const appKey = '4Q5J5MQI98QP68DLG56YCKVMB42JC6QJ';

export const http = createHttpClient(ENV_API, appId, appKey);
