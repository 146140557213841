// import idcard from 'idcard';

/**
 * 获取url参数
 *
 * @export
 * @param {*} search
 * @returns
 */
export function parseSearch(search) {
  return new URLSearchParams(search);
}

/**
 * 初始化页码
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getInitPage(params) {
  let initPage = 1;
  const page = Number(params.get('page'));
  !page ? (initPage = 1) : (initPage = page);
  return initPage;
}

/**
 * 返回栏目列表
 *
 * @export
 * @param {*} pathname
 * @returns
 */
export function getChannelNo(pathname) {
  const lang = getLanguage();
  const pathnameArray = pathname.split('/').filter((item) => item !== '');

  pathnameArray.unshift('');
  const object = pathnameArray.reduce((acc, cur, index) => {
    acc[index] = lang && cur ? `${cur}-${lang}` : cur;
    return acc;
  }, {});
  object.array = pathnameArray;
  object.prePath = pathnameArray.join('/');
  return object;
}

export function getLanguage() {
  const lang = new URLSearchParams(window.location.search).get('lang');
  const language = ['en'].includes(lang) ? lang : '';
  return language;
}

export function dealChannelNo(lang) {
  return (channelNo) => {
    if (lang) {
      return channelNo.split('-')[0];
    }
    return channelNo;
  };
}

export function getPlatform() {
  //   const isWap = window.location.pathname.includes('wap');
  const platform = new URLSearchParams(window.location.search).get('platform');
  //   const p = ['wx'].includes(platform) ? platform : isWap ? 'wap' : '';
  const p = ['wx'].includes(platform) ? platform : '';
  return p;
}
export const searchPlatform = getPlatform() ? `?platform=${getPlatform()}` : '';
export const searchLang = getLanguage() ? `?lang=${getLanguage()}` : '';
export const HyphenLang = getLanguage() ? `-${getLanguage()}` : '';

export function validator() {
  return {
    phoneNumRegex: /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[01356789]\d{2}|4(?:0\d|1[0-2]|9\d))|9[189]\d{2}|6[567]\d{2}|4[579]\d{2})\d{6}$/, // 中国大陆手机号匹配
    userNameRegex: /^[a-zA-Z_][a-zA-Z0-9_]{1,17}$/, // 用户名
    pwdRegex: /^[\w_]{6,16}$/, // 6-16位  字母数字下划线
    fullNameRegex: /^[\u4e00-\u9fa5]{2,5}(?:·[\u4e00-\u9fa5]+)?$/, // 汉字姓名正则
    // idcardRegex: (IdNum) => idcard.verify(IdNum), // 身份证
    contentRegex: /^(<\s*\/?\s*[a-zA-z_]([^>]*?["][^"]*["])*[^>"]*>)/g, // 留言内容
    chineseRegex: /[\u4e00-\u9fa5]/, // 中文
    sCEURegex: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/ // 中文，英文字母和数字及下划线
  };
}
/**
 * 复制文字到粘贴板
 * @param {string} value 要复制的文字
 */
export function copy(value) {
  const transfer = document.createElement('input');
  document.body.appendChild(transfer);
  transfer.value = value;
  transfer.focus();
  transfer.select();
  if (document.execCommand('copy')) {
    document.execCommand('copy');
  }
  transfer.blur();
  document.body.removeChild(transfer);
}
