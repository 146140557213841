import React from 'react';
import { Redirect } from 'react-router-dom';
import withScrollTo from '../../components/ScrollTo';
// 首页
import IndexPage from '../pages/Home';
import Introduction from '../pages/Survey/Introduction';
// import DepartmentSettings from '../pages/Survey/DepartmentSettings';
import MainDetail from '../common/MainDetail';
import ColDetail from '../common/MainDetail/CollectionDetail';
import BaseExhibition from '../pages/Exhibition/components/BaseExhibition';
import BaseExhDetail from '../common/MainDetail/BaseExhDetail';
import TempExhibition from '../pages/Exhibition/components/TempExhibition';
import TempExhDetail from '../common/MainDetail/TempExhDetail';
import Treature from '../pages/Collection/components/Treature';
import AsyncLeavewordList from '../pages/Other/LeavewordList';
import { S_RouteList } from './routes';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const mainRoutes = [
  {
    path: '/',
    component: withScrollTo(IndexPage),
    exact: true
  },
  {
    path: '/index',
    component: withScrollTo(IndexPage),
    exact: true
  },
  {
    path: '/Home',
    component: withScrollTo(IndexPage),
    exact: true
  },
  {
    path: '/Survey',
    component: () => <Redirect to="/Survey/Introduction" />,
    exact: true
  },
  {
    path: '/News',
    component: () => <Redirect to="/News/Notice" />,
    exact: true
  },
  {
    path: '/Exhibition',
    component: () => <Redirect to="/Exhibition/BaseExhibition" />,
    exact: true
  },

  {
    path: '/Collection',
    component: () => <Redirect to="/Collection/Treasure" />,
    exact: true
  },
  {
    path: '/Education',
    component: () => <Redirect to="/Education/Activities" />,
    exact: true
  },
  {
    path: '/Research',
    component: () => <Redirect to="/Research/Lectures" />,
    exact: true
  },

  {
    path: '/Service',
    component: () => <Redirect to="/Service/Visit" />,
    exact: true
  }
];

const surveyRoutes = [
  {
    path: '/Survey/Introduction',
    component: withScrollTo(Introduction),
    exact: true
  },
  {
    path: '/Survey/DepartmentSettings',
    // component: withScrollTo(DepartmentSettings),
    component: withScrollTo(MainDetail),
    exact: true
  }
];

const newsRoutes = [
  {
    path: '/News/Notice',
    component: withScrollTo(S_RouteList({ pageSize: 10, grade: 2, uiType: 'text' })),
    exact: true
  },
  {
    path: '/News/SNews',
    component: withScrollTo(S_RouteList({ pageSize: 8, grade: 2, uiType: 'common' })),
    exact: true
  },
  {
    path: '/News/PartyBuilding',
    component: withScrollTo(S_RouteList({ pageSize: 10, grade: 2, uiType: 'text' })),
    exact: true
  },
  {
    path: '/News/Video',
    component: withScrollTo(S_RouteList({ pageSize: 6, grade: 2, uiType: 'img' })),
    exact: true
  },
  {
    path: '/News/:sec/:id',
    component: withScrollTo(MainDetail),
    exact: true
  }
];

const exhRoutes = [
  {
    path: '/Exhibition/BaseExhibition',
    component: withScrollTo(BaseExhibition),
    exact: true
  },
  {
    path: '/Exhibition/BaseExhibition/:id',
    component: withScrollTo(BaseExhDetail),
    exact: true
  },
  {
    path: '/Exhibition/TempExhibition',
    component: withScrollTo(TempExhibition),
    exact: true
  },
  {
    path: '/Exhibition/TempExhibition/:id',
    component: withScrollTo(TempExhDetail),
    exact: true
  },
  {
    path: '/Exhibition/VirtualExhibition',
    component: withScrollTo(S_RouteList({ pageSize: 3, grade: 2, uiType: 'imgdetail' })),
    exact: true
  },
  {
    path: '/Exhibition/VirtualExhibition/:id',
    component: withScrollTo(MainDetail),
    exact: true
  }
];

const colRoutes = [
  {
    path: '/Collection/:sec',
    component: Treature,
    exact: true
  },

  {
    path: '/Collection/:sec/:id/:groupId',
    component: withScrollTo(ColDetail),
    exact: true
  }
];

const eduRoutes = [
  {
    path: '/Education/:sec',
    component: withScrollTo(S_RouteList({ pageSize: 8, grade: 2, uiType: 'common' })),
    exact: true
  },
  {
    path: '/Education/:sec/:id',
    component: withScrollTo(MainDetail),
    exact: true
  }
];

const resRoutes = [
  {
    path: '/Research/Lectures',
    component: withScrollTo(S_RouteList({ pageSize: 8, grade: 2, uiType: 'lecture' })),
    exact: true
  },
  {
    path: '/Research/AcademicJournals',
    component: withScrollTo(S_RouteList({ pageSize: 8, grade: 2, uiType: 'book' })),
    exact: true
  },
  {
    path: '/Research/Achievements',
    component: withScrollTo(S_RouteList({ pageSize: 8, grade: 2, uiType: 'book' })),
    exact: true
  },
  {
    path: '/Research/:sec',
    component: withScrollTo(S_RouteList({ pageSize: 8, grade: 2, uiType: 'common' })),
    exact: true
  },
  {
    path: '/Research/:sec/:id',
    component: withScrollTo(MainDetail),
    exact: true
  }
];
const serviceRoutes = [
  {
    path: '/Service/Cultural',
    component: withScrollTo(S_RouteList({ pageSize: 6, grade: 2, uiType: 'img', cls: 'wc-list' })),
    exact: true
  },
  {
    path: '/Service/Appointment',
    component: withScrollTo(AsyncLeavewordList),
    exact: true
  },
  {
    path: '/Service/Download',
    component: withScrollTo(S_RouteList({ pageSize: 6, grade: 2, uiType: 'img' })),
    exact: true
  },
  {
    path: '/Service/:sec',
    component: withScrollTo(MainDetail),
    exact: true
  },
  {
    path: '/Service/:sec/:id',
    component: withScrollTo(MainDetail),
    exact: true
  }
];

export const pcRoutes = mainRoutes.concat(
  surveyRoutes,
  newsRoutes,
  exhRoutes,
  colRoutes,
  eduRoutes,
  resRoutes,
  serviceRoutes
);
