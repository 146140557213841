/**
 * @file   过滤器
 * @update zhangc 2020/11/20  藏品增加来源
 * @update zhangc 2020/11/23  添加交流展览过滤器
 */
import { getFirstImg, showDateTime } from '../../../utils';
import moment from 'moment';
import dayjs from 'dayjs';
export const html2text = (content, { length } = { length: 100, wordBreak: false }) => {
  const s = content?.replace(/<[^>]+>|&[^>]+;/g, '').trim();
  if (s === '' || s === undefined) return '';
  return s.substr(0, length) + '...';
};

const assetsPrefix = window.ENV_IMG_PREURL;
/**
 * 格式化列表
 *
 * @export
 * @param {*} data 元数据
 * @returns
 */
export function listFilter(data) {
  return data?.map(detailFilter);
}

/**
 * 格式化详情
 *
 * @export
 * @param {*} item
 */
export function detailFilter(item) {
  return {
    id: item.archivesId, // 文章id
    channelId: item.channelId,
    channelNo: item.channelNo, // 栏目编号
    channelName: item.channelName, // 栏目名称
    title: item.title, // 标题
    subTitle: item.subTitle, // 副标题
    publishDate: (item.publishDate && item.publishDate.split(' ')[0]) ?? '',
    parentChannelNo: item.parentChannelNo, // 父栏目
    imgSrc: item.thumbImg
      ? item.thumbImg
      : getFirstImg(item.content).replace('/f/uploads/', '/uploads/'),
    appImgSrc: item.thumbAppImg ?? '', // app缩略图，默认为''
    content: item.content, // 详情
    abstract: item.description, // 摘要内容
    source: item.source ?? '', // 文章来源
    linkUrl: item.linkUrl ?? '', // 自定义链接,
    isShowTitle: item.isShowTitle, // 是否显示标题
    visitCount: item.visitCount,
    flag: item.flag,
    groupId: item.groupId,
    extension1: item.extension1,
    extension2: item.extension2,
    extension3: item.extension3,
    extension4: item.extension4,
    extension5: item.extension5,
    extension6: item.extension6,
    label: item.label // 标签
  };
}

/**
 * 格式化临展列表
 *
 * @export
 * @param {*} data
 */
export function exhListFilter(data) {
  return data.map(exhDetailFilter);
}

/**
 * 格式化临展详情
 *
 * @export
 * @param {*} data
 */
export function exhDetailFilter(item) {
  return {
    id: item.exhibitionTempId,
    groupId: item.groupId,
    title: item.tempName,
    content: item.description,
    startTime: item.startTime ? item.startTime.split(' ')[0] : '',
    endTime: item.endTime ? item.endTime.split(' ')[0] : '',
    // exhDate: showDateTime(
    //   item.startTime ? item.startTime.split(' ')[0] : '',
    //   item.endTime ? item.endTime.split(' ')[0] : ''
    // ),
    exhDate: `${item.startTime ? item.startTime.split(' ')[0].replace(/-/g, '.') : ''} - ${
      item.endTime ? item.endTime.split(' ')[0].replace(/-/g, '.') : ''
    }`,
    place: item.tempPlace,
    linkUrl: item.linkUrl,
    extension1: item.extension1,
    imgSrc: item.thumb
      ? item.thumb
      : '' || getFirstImg(item.description).replace('/f/uploads/', '/uploads/'),
    appimgSrc: item.appThumb ? item.appThumb : '',
    label: item.label
  };
}

/**
 * 格式化常展列表
 *
 * @export
 * @param {*} data
 */
export function exhBaseListFilter(data) {
  return data.map(exhBaseDetailFilter);
}

/**
 * 格式化常展详情
 *
 * @export
 * @param {*} data
 */
export function exhBaseDetailFilter(item) {
  return {
    id: item.exhibitionBaseId,
    groupId: item.groupId,
    title: item.baseName,
    content: item.description,
    startTime: item.startTime ? item.startTime.split(' ')[0] : '',
    endTime: item.endTime ? item.endTime.split(' ')[0] : '',
    // exhDate: showDateTime(
    //   item.startTime ? item.startTime.split(' ')[0] : '',
    //   item.endTime ? item.endTime.split(' ')[0] : ''
    // ),
    exhDate: `${item.startTime ? item.startTime.split(' ')[0].replace(/-/g, '.') : ''} - ${
      item.endTime ? item.endTime.split(' ')[0].replace(/-/g, '.') : '长期'
    }`,
    x: item.extension1,
    y: item.extension2,
    extension3: item.extension3,
    linkUrl: item.linkUrl,
    place: item.basePlace,
    imgSrc: item.thumb
      ? item.thumb
      : '' || getFirstImg(item.description).replace('/f/uploads/', '/uploads/'),
    appimgSrc: item.appThumb ? item.appThumb : '',
    label: item.label // 标签
  };
}

/**
 * 格式化文件列表
 *
 * @export
 * @param {*} data
 */
export function fileFilter(data) {
  return data.map((item) => {
    return {
      id: item.fileInfoId,
      relateId: item.objectId,
      title: item.fileOldname.split('.')[0],
      type: item.fileSuffix,
      content: item.description,
      fileName: item.fileName,
      fileOldname: item.fileOldname,
      filePath: item.filePath ? assetsPrefix + item.filePath + item.fileName : ''
    };
  });
}

/**
 * 格式化藏品列表
 *
 * @export
 * @param {*} data
 */
export function colListFilter(data) {
  return data.map((item) => colDetailFilter(item));
}

/**
 * 格式化藏品详情
 *
 * @export
 * @param {*} data
 */
export function colDetailFilter(item) {
  return {
    id: item.exhibitId,
    title: item.exhibitName,
    type: item.exhibitType,
    modelUrl: item.modelUrl,
    level: item.exhibitLevel,
    age: item.age,
    ageDetail: item.ageDetail,
    size: item.size,
    content: item.description,
    groupId: item.groupId,
    explanationWord: item.explanationWord,
    imgSrc: item.thumb ? item.thumb : '',
    appimgSrc: item.appThumb ? item.appThumb : '',
    collectionUnit: item.collectionUnit,
    source: item.extension6 ? item.extension6 : '',
    isOwn: item.isOwn,
    label: item.label,
    generalType: item.generalType
  };
}

/**
 * 格式化活动列表
 *
 * @export
 * @param {*} data
 */
export function actListFilter(data) {
  return data.map(actDetailFilter);
}

/**
 * 格式化活动详情
 *
 * @export
 * @param {*} data
 */
export function actDetailFilter(item) {
  return {
    id: item.activityId,
    groupId: item.groupId,
    title: item.activityName,
    acttype: item.activityType,
    place: item.activityVenueName, // 活动地点
    content: item.description,
    startTime: item.startTime ? moment(item.startTime).format('YYYY-MM-DD HH:mm') : '',
    endTime: item.endTime ? moment(item.endTime).format('YYYY-MM-DD HH:mm') : '',
    actDate: showDateTime(
      item.startTime ? moment(item.startTime).format('YYYY-MM-DD HH:mm') : '',
      item.endTime ? moment(item.endTime).format('YYYY-MM-DD HH:mm') : '',
      true
    ),
    reserveStartTime: item.reserveStartTime
      ? moment(item.reserveStartTime).format('YYYY-MM-DD HH:mm')
      : '',
    reserveEndTime: item.reserveEndTime
      ? moment(item.reserveEndTime).format('YYYY-MM-DD HH:mm')
      : '',
    reserveDate: showDateTime(
      item.reserveStartTime ? moment(item.reserveStartTime).format('YYYY-MM-DD HH:mm') : '',
      item.reserveEndTime ? moment(item.reserveEndTime).format('YYYY-MM-DD HH:mm') : '',
      true
    ),
    reserveType: item.reserveType, // 预约类型 0:报名未开始 -1:报名已结束
    reserveStatus: item.reserveStatus, // 预约状态
    activityStatus: item.activityStatus, // 活动状态 -1:活动已结束
    minAge: item.minAge,
    maxAge: item.maxAge,
    linkUrl: item.linkUrl,
    imgSrc: item.thumb
      ? item.thumb
      : '' || getFirstImg(item.description).replace('/f/uploads/', '/uploads/'),
    appimgSrc: item.appThumb ? item.appThumb : '',
    label: item.label,
    capacityNum: item.capacityNum, // 参加人数
    activityObject: item.activityObject, // 活动对象
    price: item.price, // 活动费用
    lectureTopic: item.lectureTopic // 讲座主题
  };
}

/**
 * 格式化全文搜索列表
 *
 * @export
 * @param {*} data
 */
export function searchListFilter(data) {
  return data.map((item) => {
    // let type = 0;
    // switch (item.type) {
    //   case 'archives':
    //     type = 0;
    //     break;
    //   case 'activity':
    //     type = 1;
    //     break;
    //   case 'exhibit':
    //     type = 2;
    //     break;
    //   case 'exhibitionTemp':
    //     type = 3;
    //     break;
    //   case 'exhibitionBase':
    //     type = 4;
    //     break;
    //   default:
    //     break;
    // }
    return {
      id: item.archivesId, // 文章id
      esId: item.esId, // 文章id
      groupId: item.groupId, // 附件id
      channelId: item.channelId, // 栏目编号
      channelNo: item.channelNo ? item.channelNo : item.type, // 栏目
      title: item.title, // 标题
      imgSrc: item.thumbImg, // 缩略图，默认为'' 字符串作为 URI 进行编码
      abstract: item.description, // 摘要内容
      content: item.content, // 详情
      linkUrl: item.linkUrl || '', // 自定义链接,
      startTime: item.extension1, // 开始时间
      endTime: item.extension2, // 结束时间
      exhibitId: item.exhibitId, // 展品id
      exhibitionBaseId: item.exhibitionBaseId, // 常展id
      exhibitionTempId: item.exhibitionTempId, // 临展id
      activityId: item.activityId // 活动id
      // type: type
    };
  });
}

export function leaveWordListFilter(data) {
  return data.map((item) => {
    return {
      id: item.messageId, // 留言id
      source: item.source, // 来源
      content: item.content, // 留言内容
      createTime: item.createTime, // 留言时间
      email: item.email, // 留言用户email
      name: item.name, // 留言用户姓名
      phoneNumber: item.phoneNumber, // 留言用户电话号码
      type: item.type, // 留言类型
      reply: item.replyMessage // 回复
    };
  });
}
export function exhibitionTypeFilter(data) {
  return data.map((item) => {
    return {
      id: item.exhibitTypeId,
      name: item.typeName,
      type: item.typeNo,
      imgSrc: item.backGroundImg
    };
  });
}
export function dictionaryTypeFilter(data) {
  return data.map((item) => {
    return {
      id: item.dictionaryId,
      name: item.dictionaryName,
      type: item.dictionaryValue,
      nameEn: item.dictionaryNameEn
    };
  });
}

export function labelSearchFilter(data) {
  return data.map((item) => {
    return {
      id: item.id,
      publishDate: dayjs(item.publishTime).format('YYYY-MM-DD'),
      title: item.title,
      content: item.content,
      channelNo: item.channelNo,
      linkUrl: item.linkUrl,
      flag: item.flag,
      extension1: item.extension1,
      extension2: item.extension2,
      extension3: item.extension3,
      extension4: item.extension4,
      extension5: item.extension5,
      extension6: item.extension6,
      imgSrc: item.img,
      subType: item.subType,
      orgName: item.orgName,
      orgId: item.orgId,
      portalId: item.portalId
    };
  });
}
