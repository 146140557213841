/* eslint-disable complexity */

import React from 'react';
import { htmltoStr, getNowFormatDate } from '@/utils';
import { Picture } from '@portal-site/components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import cls from 'classnames';
export default function Baselist({ data, prePath, isExh, isAct, isEn }) {
  const timeNow = getNowFormatDate();
  const enDate = 'Date';
  const enLocation = 'Location';
  dayjs.extend(isBetween);
  let isNow = false;
  return (
    <ul className="row-one-pic-list">
      {data.map((item, index) => {
        if (!item.endTime) {
          isNow = true;
        } else {
          isNow = item.endTime >= timeNow;
        }
        const unstart = item.startTime >= timeNow;
        const end = item.endTime && item.endTime < timeNow;

        return (
          <li key={item.id}>
            <Link to={`${prePath}/${item.id}`}>
              <div className="l">
                <Picture source={item.imgSrc} />
              </div>
              <div className="r">
                <div className="top">
                  <h2>{item.title}</h2>
                  {isExh && (
                    <>
                      <p className="time">
                        <i className="iconfont icon-shijian1" />
                        <span>{!isEn ? '展览时间' : enDate}：</span>
                        <p>{!item.startTime && !item.endTime ? '常年陈列' : item.exhDate}</p>
                        <span className={cls('statue', { now: isNow || unstart })}>
                          {!isEn
                            ? isNow || (!item.startTime && !item.endTime)
                              ? '【在展】'
                              : unstart
                              ? '【未开始】'
                              : end
                              ? '【结束】'
                              : ''
                            : ''}
                          {isEn
                            ? end
                              ? `(ended)`
                              : unstart
                              ? `(not started)`
                              : isNow || (!item.startTime && !item.exhDate)
                              ? `(doing)`
                              : ''
                            : ''}
                        </span>
                      </p>
                      <p className="time margin">
                        <i className="iconfont icon-dingwei" />
                        <span>{!isEn ? '展览地点' : enLocation}：</span>
                        {item.place}
                      </p>
                    </>
                  )}
                  {(prePath?.includes('Activity') || isAct) && (
                    <div className="txt">
                      {item.activityObject && (
                        <p>
                          活动对象：<span>{item.activityObject}</span>
                        </p>
                      )}
                      {item.place && (
                        <p>
                          活动地点：<span>{item.place}</span>
                        </p>
                      )}
                      {item.price && (
                        <p>
                          活动费用：<span>{item.price || '免费'}</span>
                        </p>
                      )}
                    </div>
                  )}
                  {prePath?.includes('Lecture') && (
                    <div className="txt">
                      {item.extension1 && (
                        <p>
                          讲座时间：<span>{item.extension1}</span>
                        </p>
                      )}
                      {item.extension2 && (
                        <p>
                          讲座主题：<span>{item.extension2}</span>
                        </p>
                      )}
                    </div>
                  )}
                  <p className={cls('content', { encontent: isEn })}>
                    {isEn ? htmltoStr(item.content) : htmltoStr(item.content, 60)}
                  </p>
                </div>
                <Link className="com-btn-more" to={`${prePath}/${item.id}`}>
                  {!isEn ? '查看详情' : 'Details'}
                </Link>
              </div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
