/**
 * @file   请求接口
 * @create zhangchen 2020/11/06
 */
import {
  listFilter,
  detailFilter,
  fileFilter,
  exhListFilter,
  exhBaseListFilter,
  exhDetailFilter,
  colListFilter,
  colDetailFilter,
  actListFilter,
  actDetailFilter,
  exhBaseDetailFilter,
  exhibitionTypeFilter,
  searchListFilter,
  dictionaryTypeFilter,
  leaveWordListFilter
} from './filter';
import { CMSGATEWAY, FILEGATEWAY, SEARCHGATEWAY } from './http';
function dataProvider(http) {
  const baseProvider = {
    // 文章
    async queryArchivesById(id) {
      const res = await http.get(`/${CMSGATEWAY}/api/queryArchivesById/${id}`);
      const data = res.data.data;
      return {
        records: detailFilter(data)
      };
    },
    async queryArchivesByNo(no) {
      const res = await http.get(`/${CMSGATEWAY}/api/queryArchivesByNo/${no}`);
      const data = res.data.data;
      return {
        records: detailFilter(data)
      };
    },
    async queryArchivesList(options = { pagination: {}, resource: '', params: {} }) {
      const { pagination, params, resource } = options;
      if (!resource)
        return {
          records: [],
          pages: 0,
          page: 0,
          total: 0,
          $$rawData: {}
        };
      const res = await http.post(`/${CMSGATEWAY}/api/queryArchivesList`, {
        entity: {
          channelNo: resource,
          ...params
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: listFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 常展
    async queryExhibitionBaseList(options = { pagination: {}, params: {} }) {
      const { pagination } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/queryExhibitionBaseList`, {
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: exhBaseListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    async queryExhibitionBaseById(id) {
      const res = await http.get(`/${CMSGATEWAY}/api/queryExhibitionBaseById/${id}`);
      const data = res.data.data;
      return {
        records: exhBaseDetailFilter(data),
        $$rawData: data
      };
    },
    // 临展
    async queryExhibitionTempList(options = { pagination: {}, params: {} }) {
      const { pagination, params = {} } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/queryExhibitionTempList`, {
        entity: {
          isRecommend: params.isRecommend
        },
        param: {
          type: params.type,
          pageSize: pagination.size,
          pageNum: pagination.page,
          str: params.str
        }
      });
      const data = res.data.data;
      return {
        records: exhListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    async queryExhibitionTempById(id) {
      const res = await http.get(`/${CMSGATEWAY}/api/queryExhibitionTempById/${id}`);
      const data = res.data.data;
      return {
        records: exhDetailFilter(data),
        $$rawData: data
      };
    },
    // 展品列表
    async queryExhibitList(options = { pagination: {}, params: {} }) {
      const { pagination, params = {} } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/queryExhibitList`, {
        entity: {
          exhibitType: params.type,
          age: params.age,
          exhibitName: params.name
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: colListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 展品详情
    async queryExhibitById(id) {
      const res = await http.get(`/${CMSGATEWAY}/api/queryExhibitById/${id}`);
      const data = res.data.data;
      return {
        records: colDetailFilter(data),
        $$rawData: data
      };
    },
    // 关联展品
    async queryExhibitListByTemp(options = { pagination: {}, params: {} }) {
      const { pagination, params = {} } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/queryExhibitListByTemp`, {
        entity: {
          groupId: params.groupId
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: colListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 展品类型
    async queryExhibitTypeList() {
      const res = await http.post(`/${CMSGATEWAY}/api/queryExhibitTypeList`, {
        entity: {},
        param: {
          pageSize: 0,
          pageNum: 0
        }
      });
      const data = res.data.data;
      return {
        records: exhibitionTypeFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 活动
    async queryActivityListByType(
      options = { pagination: {}, resource: '', params: { entityParams: {}, params: {} } }
    ) {
      const { pagination, params, resource } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/queryActivityListByType`, {
        entity: {
          activityType: resource,
          ...params.entityParams
        },
        param: {
          // type: params.param.type, 正在1,回顾2
          pageSize: pagination.size,
          pageNum: pagination.page,
          ...params.params
        }
      });
      const data = res.data.data;
      return {
        records: actListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    async queryActivityById(id) {
      const res = await http.get(`/${CMSGATEWAY}/api/queryActivityById/${id}`);
      const data = res.data.data;
      return {
        records: actDetailFilter(data),
        $$rawData: data
      };
    },
    // 附件
    async queryFileList(options = { pagination: {}, params: {} }) {
      const result = {
        records: [],
        pages: 0,
        page: 0,
        total: 0,
        $$rawData: {}
      };
      if (!options.params.groupId) return result;

      const { pagination, params } = options;

      const res = await http.post(`/${FILEGATEWAY}/refFileObject/queryPageList`, {
        entity: {
          objectId: params.groupId, // 关联id
          code: params.code ? params.code : '' // 文件类型
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: fileFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },

    // 全文检索
    async searchAll(options = { pagination: {}, params: {} }) {
      const result = {
        records: [],
        pages: 0,
        page: 0,
        total: 0,
        $$rawData: {}
      };
      if (!options.params.keywords) return result;

      const { pagination, params } = options;

      // const res = await http.post(`/sw-es/xpackSearch`, {
      //   clusterName: params.clusterName,
      //   pageNum: pagination.page,
      //   pageSize: pagination.size,
      //   searchValue: params.keywords,
      //   searchMap: {
      //     subType: params.subType
      //   }
      // });
      const res = await http.post(`/${SEARCHGATEWAY}/cmsSearch`, {
        clusterName: params.clusterName,
        pageNum: pagination.page,
        pageSize: pagination.size,
        searchValue: params.keywords,
        searchMap: {
          equalsMap: `{"subType": "${params.subType}"}`
        }
      });
      const data = res.data.data;
      return {
        records: searchListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // // 字典查询
    // async queryDictionary(options = { namespace: '' }) {
    //   const { namespace } = options;
    //   const res = await http.post(
    //     `/${CMSGATEWAY}/api/queryDictionaryByCode?dictionaryCode=${namespace}&appId=${appId}`,
    //     {
    //       namespace
    //     }
    //   );
    //   const data = res.data.data;
    //   return {
    //     records: dictionaryTypeFilter(data),
    //     pages: data.pages,
    //     page: data.current,
    //     total: data.total,
    //     $$rawData: data
    //   };
    // }
    // 字典查询
    async queryDictionary(options = { namespace: '' }) {
      const { namespace, systemCode, prefix } = options;
      const res = await http.post(
        `/${CMSGATEWAY}/api/queryDictionaryListByPrefix?systemCode=${systemCode}&prefix=${prefix}`,
        {
          namespace
        }
      );
      const data = res.data.data;
      return {
        records: dictionaryTypeFilter(data),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    /**
     * @kind javaapi
     * @description 留言列表
     * @param {Object} options
     */

    async queryMessageList(options = { pagination: {}, params: {} }) {
      const { pagination, params = {} } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/queryMessageList`, {
        entity: {
          ...params
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: leaveWordListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    /**
     * @kind javaapi
     * @description 添加留言
     * @param {Object} options
     */
    async addMessage(options) {
      const { content, name, phoneNumber, email, type } = options;
      const res = await http.post(`/${CMSGATEWAY}/api/addMessage`, {
        content,
        name,
        phoneNumber,
        email,
        type,
        // isAnonymous,
        // source,
        orgId: 2
      });
      const data = res.data;
      return {
        data: data.data,
        msg: data.msg,
        code: data.code
      };
    }
  };
  const enhanceProvider = {
    async queryColDetail(options = { pagination: {}, params: {} }) {
      const { pagination, params } = options;
      const a = {
        pagination,
        params: {
          groupId: params.groupId,
          code: 'CMS_EXHIBIT_INFO_DISPLAY'
        }
      };
      const b = params.id;
      return Promise.all([baseProvider.queryFileList(a), baseProvider.queryExhibitById(b)]);
    }
  };
  return { ...baseProvider, ...enhanceProvider };
}

export default dataProvider;
