import { css, cx } from 'emotion';
import React, { forwardRef } from 'react';

export const labelStyle = css`
  display: inline-block;
  text-align: right;
  line-height: 32px;
  color: #737373;
  width: 130px;
  padding-right: 12px;
  font-size: 16px;
  &.required::before {
    content: '*';
    vertical-align: sub;
    color: #f00;
    padding-right: 3px;
  }
`;
export const fieldStyle = css`
  font-size: 14px;
  display: inline-block;
`;

export const inputStyle = css`
  color: #393939;
  border: 1px solid #dadada;
  padding: 3px;
  height: 34px;
  font-size: 14px;
  text-indent: 3px;
  box-sizing: border-box;
  background: #fff;
`;
export const Input = forwardRef(({ label, name, required = false, width, className }, ref) => {
  return (
    <div className={cx(fieldStyle, className)}>
      <label className={cx(labelStyle, { required })}>{label}:</label>
      <input
        style={{
          width
        }}
        className={inputStyle}
        ref={ref}
        type="text"
        name={name}
      />
    </div>
  );
});
