import { css, cx } from 'emotion';
import React, { forwardRef } from 'react';
import { inputStyle, labelStyle } from './Input';

export const Textarea = forwardRef(({ label, required, width, rows = 2, ...rest }, ref) => {
  return (
    <div
      className={css`
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        flex-wrap: wrap;
      `}
    >
      <label className={cx(labelStyle, { required })}>{label}:</label>
      <textarea
        ref={ref}
        style={{
          height: 'auto',
          resize: 'vertical',
          width
        }}
        rows={rows}
        className={inputStyle}
        {...rest}
      />
    </div>
  );
});
