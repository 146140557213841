import React, { lazy, Suspense } from 'react';
import Loading from '@/app/components/Loading';

// const hoc = function(WrapLazy) {
//   return function(props) {
//     return function() {
//       return (
//         <Suspense fallback={<Loading />}>
//           <WrapLazy {...props} />
//         </Suspense>
//       );
//     };
//   };
// };

const hoc2 = function(WrapLazy) {
  return function(props) {
    return function() {
      return (
        <Suspense fallback={<Loading />}>
          <WrapLazy {...props} />
        </Suspense>
      );
    };
  };
};

// const Aside = lazy(() => import('../common/Aside'));
// const [S_Aside] = [hoc(Aside)];

// 其他
const RouteList = lazy(() => import('../common/List/RouteList'));

const S_RouteList = hoc2(RouteList);
export { S_RouteList };
